<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="fa fa-truck"></i> Transportation</b-card-title>
			<b-card-sub-title>Manages all types of transport within the supply chain</b-card-sub-title>
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Plate No/CR/OR">
											<v-select class="style-chooser" label="text" :options="plateNoOptions"
												:reduce="(company) => company.value" v-model="filterBy.plateNo">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-transportation v-show="!isViewer">
								Add Transportation
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Transportations in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Transportations to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(actions)="row">
						<b-button size="sm" v-b-modal.edit-transportation v-b-tooltip.hover.top="'Edit Details'"
							variant="dark" @click.stop="updateSelTransportation(row.item)" class="mr-1" v-show="!isViewer">
							<i class="fa fa-pencil"></i>
						</b-button>
						<b-button size="sm" v-b-modal.print-transportation v-b-tooltip.hover.top="'Print QR Code'"
							variant="warning" @click.stop="printQrCode(row.item)" class="mr-1" v-show="!isViewer">
							<i class="fa fa-print"></i>
						</b-button>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelTransportation(row.item)" v-if="!isViewer">
							<b-form-checkbox name="status" v-b-modal.activate-transportation v-model="row.item.isActive"
								switch size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>{{
							row.item.isActive === 'true' ? 'Active' : 'Inactive'
						}}</span>
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<AddTransportation :companyFilter="filterBy.company" :companyOptions="companyOptions"
			:allTransportationsObj="allTransportationsObj" />
		<EditTransportation :allCompaniesObj="allCompaniesObj" :companyOptions="companyOptions"
			:allTransportationsObj="allTransportationsObj" />
		<PrintTransportation />

		<ActivateTransportation />
	</div>
</template>

<script>
// Component
import AddTransportation from './transportation/AddTransportation';
import EditTransportation from './transportation/EditTransportation';
import ActivateTransportation from './transportation/ActivateTransportation';
import PrintTransportation from './transportation/PrintTransportation.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import transportationApi from '@/api/transportationApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'transportation',
	components: {
		AddTransportation,
		EditTransportation,
		ActivateTransportation,
		PrintTransportation,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'plateNo',
					label: 'Plate No/CR/OR',
					sortable: true,
				},
				{
					key: 'description',
					sortable: true,
				},
				{
					key: 'company',
					sortable: true,
				},
				'actions',
				'status',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			companyOptions: [],
			statusOptions: config.statusOptionsWithDefault,
			plateNoOptions: [],

			allCompaniesObj: {},
			allTransportationsObj: {},
			selTransportation: {},

			defaultFilterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				isActive: 'true',
			},
			filterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				isActive: 'true',
			},
			prevFilter: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Plate No/CR/OR': 'plateNo',
				Description: 'description',
				Company: 'company',
				Status: 'status',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'Transportation-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
			);
		},
	},
	mounted() {
		if (this.isViewer) {
			this.fields = this.fields.filter((label) => {
				return label != 'actions';
			});
		}

		setTimeout(() => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// Show loader
				this.isLoading = true;

				this.allCompaniesObj = this.$store.getters.companies;
				this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);

				if (!this.isSuperAdmin) {
					this.filterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
				}

				this.allTransportationsObj = this.$store.getters.transportations;
				this.plateNoOptions = DropDownItemsUtil.retrievePlateNoItems(
					this.allTransportationsObj
				);

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddTransportation', (transportationObj) => {
			this.updateTable(transportationObj);
		});
		EventBus.$on('onCloseEditTransportation', (transportationObj) => {
			this.updateTable(transportationObj);
		});
	},
	methods: {
		updateTable(transportationObj) {
			if (!_.isEmpty(transportationObj)) {
				this.allTransportationsObj[transportationObj.id] = transportationObj;
				this.$store.dispatch(
					'updateAllTransportations',
					this.allTransportationsObj
				);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObj = Object.assign({}, this.allTransportationsObj);

			_.forEach(this.allTransportationsObj, (transportation, transpoId) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== transportation.companyId
				) {
					delete filteredObj[transpoId];
				}

				if (this.filterBy.isActive !== transportation.isActive) {
					delete filteredObj[transpoId];
				}

				let plateNo = this.filterBy.plateNo;
				if (
					plateNo &&
					plateNo.length > 0 &&
					plateNo !== transportation.plateNo
				) {
					delete filteredObj[transpoId];
				}
			});

			this.items = Object.values(filteredObj);
			this.items.forEach((item) => {
				item['status'] = item.isActive === 'true' ? 'Active' : 'Inactive';
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});

			this.items = _.sortBy(this.items, ['plateNo']);
			this.totalRows = this.items.length;
		},
		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);

				if (this.isSuperAdmin) {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await transportationApi.getTransportations(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allTransportationsObj = _.assign(
						this.allTransportationsObj,
						data.transportations
					);
				} else {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await transportationApi.getTransportations(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allTransportationsObj = _.assign(
						this.allTransportationsObj,
						data.transportations
					);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateSelTransportation(item) {
			this.selTransportation = item;
			this.$store.commit('SET_CURR_TRANSPORTATION', item);
			EventBus.$emit('onUpdateSelTransportation', item);
		},
		printQrCode(item) {
			this.selTransportation = item;
			this.$store.commit('SET_CURR_TRANSPORTATION', item);
			EventBus.$emit('onPrintTransportation', item);
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddTransportation');
		EventBus.$off('onCloseEditTransportation');
	},
};
</script>
<style scoped>
.fa-print {
	color: #fff
}
</style>
