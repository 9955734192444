<template>
	<b-modal id="edit-transportation" title="Edit Transportation" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-12">
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Company">
							<v-select name="Company" class="style-chooser" label="text" :options="companyOptions"
								:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Plate No/CR/OR" label-for="plateNo" description>
							<b-form-input id="plateNo" name="Plate No" type="text" v-model="form.plateNo" v-validate="{
								required: true,
								regex: /^[a-zA-Z0-9_ -]{6,12}$/,
							}" maxlength="12" placeholder="Plate No/Certification of Registration (CR)/OR" readonly />
							<span v-show="errors.has('Plate No')" class="help-block">{{
								errors.first('Plate No')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-form-group label-for="hasCheckingInterval">
					<b-form-checkbox id="hasCheckingInterval" name="hasCheckingInterval" v-model="form.hasCheckingInterval">
						Checking Interval (hrs):
					</b-form-checkbox>
				</b-form-group>

				<b-form-group label-for="checkingInterval">
					<b-form-input id="checkingInterval" name="Checking Interval" type="number"
						v-model="form.checkingInterval"
						v-validate="`${form.hasCheckingInterval ? 'required|min_value:1|max_value:24' : ''}`" min="1"
						max="24" step="1" onwheel="this.blur()" placeholder="Checking Interval"
						:readonly="!form.hasCheckingInterval" />
					<span v-show="errors.has('Checking Interval')" class="help-block">{{
						errors.first('Checking Interval')
					}}</span>
				</b-form-group>

				<b-row class="my-12">
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Description:" label-for="description" description>
							<b-form-textarea name="Description" type="text" v-model="form.description" maxlength="200"
								:rows="3" placeholder="Description"
								v-validate="{
										required:true,
										regex: /^([0-9:;,.'\\\/()\-_a-zA-Z\n ])*$/
									}" />
							<span v-show="errors.has('Description')" class="help-block">{{
								errors.first('Description')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'edit-transportation',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		companyOptions: {
			type: Array,
			required: true,
		},
		allTransportationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				id: '',
				plateNo: '',
				description: '',
				hasCheckingInterval: false,
				checkingInterval: 0,
				company: '',
				companyId: '',
				isActive: 'true',
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},
			selCompany: config.companyDefaultValue,
			selTransportation: {},
			origPlateNo: '',

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		plateNo() {
			return this.form.plateNo;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelTransportation', (selTransportation) => {
			this.selTransportation = selTransportation;
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			if (
				this.form.plateNo.length > 0 &&
				this.origPlateNo != this.form.plateNo &&
				this.hasExistingTransportation()
			) {
				this.$toaster.warning(
					'Transportation with Plate No/CR/OR ' +
					this.form.plateNo +
					' already exists.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);

			this.handleSubmit();
		},
		hasExistingTransportation() {
			let hasExisting = false;
			let transportationsArr = Object.values(this.allTransportationsObj);
			for (const transportation of transportationsArr) {
				if (transportation.plateNo === this.form.plateNo) {
					hasExisting = true;
					break;
				}
			}
			return hasExisting;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// assign the selected company to the user
			this.updateCompanySelection();

			try {
				let result = await this.$store.dispatch(
					'updateTransportation',
					this.form
				);

				// hide loading indicator
				this.isLoading = false;

				if (result.isSuccess) {
					this.$toaster.success(
						`Transportation "${this.plateNo}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditTransportation', result.data);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error updating transportation ${this.plateNo}. Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating transportation ${this.plateNo}. Please try again.`
				);
			}
		},
		updateCompanySelection() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
		},
		onReset() {
			/* Reset our form values */
			let transport = this.selTransportation;
			let tranportJSON = JSON.stringify(transport);
			this.form = JSON.parse(tranportJSON);

			if (typeof this.form.hasCheckingInterval === 'undefined') {
				this.form.hasCheckingInterval = false;
				this.form.checkingInterval = 0;
			}

			// init company
			this.selCompany = DropDownItemsUtil.getCompanyItem(
				this.allCompaniesObj[transport.companyId]
			);
			this.origPlateNo = transport.plateNo;

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelTransportation');
	},
};
</script>

<style></style>


